import React, { useState } from 'react';
import './UniqueButton.css';

const UniqueButton = ({ onClick, children }) => {
  const [ripple, setRipple] = useState({ x: 0, y: 0, isActive: false });

  const handleClick = (e) => {
    const { clientX, clientY, target } = e;
    const { left, top } = target.getBoundingClientRect();
    setRipple({
      x: clientX - left,
      y: clientY - top,
      isActive: true,
    });
    onClick && onClick(e);
    setTimeout(() => setRipple({ ...ripple, isActive: false }), 500);
  };

  return (
    <button className="serious-button" onClick={handleClick}>
      {children}
      {ripple.isActive && (
        <span
          className="ripple"
          style={{
            left: ripple.x,
            top: ripple.y,
          }}
        />
      )}
    </button>
  );
};

export default UniqueButton;
