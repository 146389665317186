import React from 'react';
import { Route, Link } from 'wouter';
import './App.css'; // Asegúrate de importar tus estilos CSS

import FraccionamientosList from './components/FraccionamientosList';
import FraccionamientoDetails from './components/FraccionamientoDetails';
import LoteDetails from './components/LoteDetails';
import AgregarIncidenciaForm from './components/AgregarIncidenciaForm';
import { Footer } from './components/footer';
import TransitionContainer from './components/TransitionContainer';
import { SoundProvider } from './components/Sound';
import TrabajadorIncidencias from './components/TrabajadorIncidencias'; // Importamos el nuevo componente

function App() {
  return (
    <SoundProvider>
      <div className="container-fluid fos">
        <nav className="bg-dark border-bottom border-body">
          <div className="container mx-auto p-4">
            <div className="flex justify-between items-center">
              <Link className="text-white btn btn-dark" to="/">
                Inicio
              </Link>
              {/* Aquí puedes agregar un enlace a la nueva sección de incidencias por trabajador */}
              <Link className="text-white btn btn-dark" to="/trabajador">
                Trabajador
              </Link>
            </div>
          </div>
        </nav>
        <div className="container mx-auto mt-4">
          <Route path="/">
            <TransitionContainer>
              <FraccionamientosList />
            </TransitionContainer>
          </Route>
          <Route path="/:constructoraId/fraccionamientos/:fraccionamientoId">
            <TransitionContainer>
              <FraccionamientoDetails />
            </TransitionContainer>
          </Route>
          <Route path="/:constructoraId/fraccionamientos/:fraccionamientoId/:numeroLote">
            <TransitionContainer>
              <LoteDetails />
            </TransitionContainer>
          </Route>
          <Route path="/agregar-incidencia">
            <TransitionContainer>
              <AgregarIncidenciaForm />
            </TransitionContainer>
          </Route>
          {/* Nueva ruta para acceder a TrabajadorIncidencias */}
          <Route path="/trabajador">
            <TransitionContainer>
              <TrabajadorIncidencias />
            </TransitionContainer>
          </Route>
        </div>
        <Footer />
      </div>
    </SoundProvider>
  );
}

export default App;
