import React, { useState, useEffect } from 'react';
import { Link, useRoute } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTimes, faCircleExclamation, faSpinner, faCheckDouble} from '@fortawesome/free-solid-svg-icons';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../styles/FraccionamientoDetails.css';
import { useSound } from './Sound';
import BackButton from './BackButton';
import FramerButton from './FramerButton';
import useSWR from 'swr'; // Importa SWR aquí
import AddLoteModal from './AddLoteModal';
import LoteList from './LoteList'; // Importa el nuevo componente

alertify.set('notifier', 'position', 'top-right');

const fetcher = (url) => fetch(url).then((response) => response.json()); // Función para cargar los datos usando SWR

const FraccionamientoDetails = ({ lotes }) => {
  const [showLotesModal, setShowLotesModal] = useState(false);
  const [showAgregarLoteModal, setShowAgregarLoteModal] = useState(false);

  const openAgregarLoteModal = () => {
    setShowAgregarLoteModal(true);
  };
  
  const closeAgregarLoteModal = () => {
    setShowAgregarLoteModal(false);
  };

  const [fraccionamiento, setFraccionamiento] = useState(null);
  const [lotesPorFraccionamiento, setLotesPorFraccionamiento] = useState([]);
  const { playSuccessSound, playErrorSound } = useSound();

  const [, params] = useRoute("/:constructoraId/fraccionamientos/:fraccionamientoId");
  const { constructoraId, fraccionamientoId } = params;

  const readyLotes = lotesPorFraccionamiento.filter((lote) => lote.estado === 'Listo');
  const readyPercentage = (readyLotes.length / lotesPorFraccionamiento.length) * 100;



  // Reemplaza el useEffect para cargar los datos usando SWR
  const { data: fraccionamientoData, error: fraccionamientoError } = useSWR(
    ` https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}`,
    fetcher
  );

  const { data: lotesData, error: lotesError } = useSWR(
    ` https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes`,
    fetcher
  );

  useEffect(() => {
    if (fraccionamientoData) {
      setFraccionamiento(fraccionamientoData);
    } else if (fraccionamientoError) {
      console.error('Error al obtener los detalles del fraccionamiento', fraccionamientoError);
    }
  }, [fraccionamientoData, fraccionamientoError]);

  useEffect(() => {
    if (lotesData) {
      const filteredLotes = lotesData.filter(lote => lote.fraccionamiento === fraccionamientoId);
      const sortedLotes = filteredLotes.sort((a, b) => a.numero_lote - b.numero_lote);
      setLotesPorFraccionamiento(sortedLotes);
    } else if (lotesError) {
      console.error('Error al obtener la lista de lotes por fraccionamiento', lotesError);
    }
  }, [lotesData, lotesError, fraccionamientoId]);

  const openLotesModal = () => {
    fetch(` https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes`)
      .then((response) => response.json())
      .then((data) => {
        setShowLotesModal(true);
      })
      .catch((error) => console.error('Error al obtener la lista de lotes', error));
  };

  const closeLotesModal = () => {
    setShowLotesModal(false);
  };

  

  const handleEstadoChange = async (numeroLote, estado, loteId) => {
    try {
      const response = await fetch(` https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ estado }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`La solicitud PUT no se realizó correctamente. Respuesta del servidor: ${errorMessage}`);
      }
  
      setLotesPorFraccionamiento((prevLotes) =>
        prevLotes.map((lote) => (lote.numero_lote === numeroLote ? { ...lote, estado } : lote))
      );
  
      if (estado === 'Listo') {
        alertify.success('Lote marcado como Listo');
        playSuccessSound();
      } else if (estado === 'proceso') {
        alertify.success('Lote en proceso');
        playSuccessSound();
      } else if (estado === 'Incidencia') {
        alertify.error('Lote en incidencia');
        playErrorSound();
      } else {
        alertify.warning('Lote en otro estado');
        playErrorSound();
      }
    } catch (error) {
      alertify.error(`Error al actualizar el estado del lote: ${error.message}`);
      playErrorSound();
      console.error(error); // Imprime el error en la consola para depuración.
    }
  };

  const handle3DEffect = (event, card) => {
    const cardRect = card.getBoundingClientRect();
    const mouseX = event.clientX - cardRect.left;
    const mouseY = event.clientY - cardRect.top;

    const rotateY = (mouseX - cardRect.width / 2) / cardRect.width * 20;
    const rotateX = -(mouseY - cardRect.height / 2) / cardRect.height * 20;
    const translateY = (mouseY - cardRect.height / 2) / cardRect.height * 20;

    card.style.transform = `perspective(1000px) translateZ(30px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) translateY(${translateY}px)`;
  };

  
  

  return (
    <div className="mt-5 text-dark rounded-lg ">
      {fraccionamiento ? (
        <div className='shadow-lg bg-black rounded-lg'>
          <h2 className="text-center text-light pt-3 mb-4">Detalles del Fraccionamiento</h2>
          <div className="card mb-4">
            <div className="card-body">
              <p className="text-black capitalize font-bold text-2xl">Nombre del Fraccionamiento: {fraccionamiento.nombre}</p>
              <BackButton destination="/" />
              <p className={`card-text percentage ${
                readyPercentage >= 90
                  ? 'text-success'
                  : readyPercentage >= 32
                  ? 'text-primary'
                  : 'text-danger'
              }`}>
                Lotes Listos: <span className="font-weight-bold">{readyLotes.length} / {lotesPorFraccionamiento.length}</span> (
                <span className="font-weight-bold">
                  {readyPercentage.toFixed(2)}%
                </span>
                )
              </p>
              <FramerButton
                className="bg-dark text-white py-2 px-4 rounded-md hover:bg-gray-900 focus:outline-none"
                onClick={openLotesModal}
              >
                Ver Lotes
              </FramerButton>
              <FramerButton
            className="bg-dark text-white mx-5 py-2 px-4 rounded-md hover:bg-gray-900 focus:outline-none"
            onClick={openAgregarLoteModal} // Abre el modal al hacer clic
          >
            Agregar Lote
          </FramerButton>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center">Cargando detalles del fraccionamiento...</p>
      )}

{lotesPorFraccionamiento.length > 0 ? (
  <div>
    <h3 className="shadow-lg txt border-info text-center mt-4">Lotes por Fraccionamiento</h3>
    <div className="row row-cols-3 row-cols-md-6 row-cols-lg-6 g-4">
      {[
        ...lotesPorFraccionamiento
          .filter(lote => lote.estado !== 'Listo') // Filtra lotes que no están "Listo"
          .sort((a, b) => a.numero_lote - b.numero_lote), // Ordena de menor a mayor
        ...lotesPorFraccionamiento
          .filter(lote => lote.estado === 'Listo') // Filtra lotes que están "Listo"
          .sort((a, b) => a.numero_lote - b.numero_lote), // Ordena de menor a mayor
      ].map((lote, index) => (
        <div
  key={index}
  className="col"
  onMouseMove={(event) => {
    const card = event.currentTarget.querySelector('.custom-card');
    handle3DEffect(event, card);
  }}
  onMouseLeave={(event) => {
    const card = event.currentTarget.querySelector('.custom-card');
    card.style.transform = 'perspective(1000px) translateZ(0) rotateX(0) rotateY(0) translateY(0)';
  }}
>
  <div
    className={`custom-card card border-0 rounded-lg shadow-lg ${lote.estado === 'Listo'
      ? 'bg-success'
      : lote.estado === 'proceso'
      ? 'bg-primary'
      : lote.estado === 'Incidencia'
      ? 'bg-danger'
      : 'bg-warning'}`}
    style={{
      width: '100%',
      height: '100%',
      transition: 'transform 0.3s ease',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
      overflow: 'hidden',
    }}
  >
    <div
      className="card-body text-center d-flex flex-column justify-content-between p-4"
      style={{
        backgroundImage: `url(https://back.ivdian.ca/${lote.imagenes.frente})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '12px',
      }}
    >
      <div className="mb-3">
        <h5
          className={`card-title font-weight-bold mb-3 text-white ${lote.estado === 'Listo'
            ? 'bg-success bg-opacity-50'
            : lote.estado === 'proceso'
            ? 'bg-primary bg-opacity-50'
            : lote.estado === 'Incidencia'
            ? 'bg-danger bg-opacity-50'
            : 'bg-warning bg-opacity-50'}`}
          style={{
            padding: '10px',
            borderRadius: '5px',
            fontSize: '1.25rem',
            textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
          }}
        >
          Lote {lote.numero_lote}
        </h5>
       
      </div>

      <div className="btn-group d-flex justify-content-center mb-3" role="group">
        <FramerButton
          className={`btn btn-sm shadow ${lote.estado === 'Listo' ? 'btn-success' : lote.estado === 'proceso' ? 'btn-primary' : lote.estado === 'Incidencia' ? 'btn-danger' : 'btn-warning'}`}
          onClick={() => handleEstadoChange(lote.numero_lote, 'Listo', lote._id)}
        >
          <FontAwesomeIcon icon={faCheckDouble} />
        </FramerButton>
        <FramerButton
          className={`btn btn-sm shadow ${lote.estado === 'Listo' ? 'btn-success' : lote.estado === 'proceso' ? 'btn-primary' : lote.estado === 'Incidencia' ? 'btn-danger' : 'btn-warning'}`}
          onClick={() => handleEstadoChange(lote.numero_lote, 'proceso', lote._id)}
        >
          <FontAwesomeIcon icon={faSpinner} />
        </FramerButton>
        <FramerButton
          className={`btn btn-sm shadow ${lote.estado === 'Listo' ? 'btn-success' : lote.estado === 'proceso' ? 'btn-primary' : lote.estado === 'Incidencia' ? 'btn-danger' : 'btn-warning'}`}
          onClick={() => handleEstadoChange(lote.numero_lote, 'Incidencia', lote._id)}
        >
          <FontAwesomeIcon icon={faCircleExclamation} />
        </FramerButton>
      </div>

      <FramerButton>
        <Link
          to={`/${constructoraId}/fraccionamientos/${fraccionamientoId}/${lote._id}`}
          className="btn btn-dark btn-sm mt-3 w-100"
        >
          <FontAwesomeIcon icon={faHome} className="mr-2" /> Entrar
        </Link>
      </FramerButton>
    </div>
  </div>
</div>

      ))}
    </div>
  </div>
) : (
  <p className="text-center">Cargando lotes...</p>
)}

      {showLotesModal && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" onClick={closeLotesModal}>
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            &#8203;
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white p-4">
                <div className="sbg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <FramerButton>
                    <div>
                      <FontAwesomeIcon icon={faTimes} onClick={closeLotesModal} className="w-full inline justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" />
                    </div>
                  </FramerButton>
                </div>
                {/* Aquí está el nuevo componente en lugar de la tabla */}
                <LoteList constructoraId={constructoraId} fraccionamientoId={fraccionamientoId} />

              </div>
              <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              </div>
            </div>
          </div>
        </div>
      )}



      {/* AgregarLoteComponent dentro de un modal */}
      {showAgregarLoteModal && (
        <AddLoteModal
          fraccionamientoId={fraccionamientoId}
          constructoraId={constructoraId}
          onAddLote={(loteData) => {
            // Agrega el lote y cierra el modal
            setLotesPorFraccionamiento((prevLotes) => [...prevLotes, loteData]);
            closeAgregarLoteModal();
          }}
          onClose={closeAgregarLoteModal}
        />
      )}
    </div>
  );
};

export default FraccionamientoDetails;  