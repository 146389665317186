import React, { useState, useEffect } from 'react';
import { useRoute } from 'wouter';

const TrabajadorIncidencias = () => {
  const [trabajadores, setTrabajadores] = useState([]);
  const [trabajadorSeleccionado, setTrabajadorSeleccionado] = useState('');
  const [incidencias, setIncidencias] = useState([]);
  const [loading, setLoading] = useState(false);

  // Obtener los parámetros de la URL con useRoute
  const [match, params] = useRoute(
    '/constructoras/:constructoraId/fraccionamientos/:fraccionamientoId/lotes/:loteId'
  );
  const { constructoraId, fraccionamientoId, loteId } = params || {};

  // Función para obtener incidencias
  const obtenerIncidencias = async () => {
    if (!constructoraId || !fraccionamientoId || !loteId) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}/incidencias`
      );
      const data = await response.json();

      // Extraer trabajadores únicos de las incidencias
      const trabajadoresUnicos = [...new Set(data.map((incidencia) => incidencia.trabajador))];

      setTrabajadores(trabajadoresUnicos);
      setIncidencias(data);
    } catch (error) {
      console.error('Error al obtener incidencias:', error);
    } finally {
      setLoading(false);
    }
  };

  // Obtener incidencias cuando cambian los parámetros de la URL
  useEffect(() => {
    obtenerIncidencias();
  }, [constructoraId, fraccionamientoId, loteId]);

  // Filtrar incidencias por trabajador seleccionado
  const incidenciasFiltradas = trabajadorSeleccionado
    ? incidencias.filter((incidencia) => incidencia.trabajador === trabajadorSeleccionado)
    : incidencias;

  return (
    <div>
      <h1>Incidencias por trabajador</h1>

      {/* Dropdown para seleccionar trabajador */}
      <div>
        <label htmlFor="trabajador">Seleccionar trabajador:</label>
        <select
          id="trabajador"
          value={trabajadorSeleccionado}
          onChange={(e) => setTrabajadorSeleccionado(e.target.value)}
        >
          <option value="">Todos los trabajadores</option>
          {trabajadores.map((trabajador, index) => (
            <option key={index} value={trabajador}>
              {trabajador}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p>Cargando incidencias...</p>
      ) : (
        <div>
          <h2>Incidencias {trabajadorSeleccionado ? `de ${trabajadorSeleccionado}` : 'de todos los trabajadores'}</h2>
          {incidenciasFiltradas.length > 0 ? (
            <ul>
              {incidenciasFiltradas.map((incidencia, index) => (
                <li key={index}>
                  <strong>Trabajador:</strong> {incidencia.trabajador}
                  <br />
                  <strong>Título:</strong> {incidencia.titulo}
                  <br />
                  <strong>Descripción:</strong> {incidencia.descripcion}
                  <br />
                  <strong>Fecha:</strong> {new Date(incidencia.fecha).toLocaleString()}
                </li>
              ))}
            </ul>
          ) : (
            <p>No se encontraron incidencias para este trabajador.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TrabajadorIncidencias;
