import React, { useState } from 'react';
import axios from 'axios';

const AgregarIncidenciaForm = ({ constructoraId, loteId, fraccionamientoId, onClose }) => {
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [trabajador, setTrabajador] = useState('');
  const [openings, setOpenings] = useState('');
  const [imagenesPorLado, setImagenesPorLado] = useState({
    frente: [],
    izquierda: [],
    derecha: [],
    atras: [],
  });
  const [lado, setLado] = useState('frente');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  // Estado para manejar errores

  // Maneja el cambio de archivo de imagen
  const handleFileChange = (e) => {
    const files = e.target.files;
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']; // Tipos de imagen válidos

    // Filtrar y añadir solo imágenes válidas
    Array.from(files).forEach((file) => {
      if (validImageTypes.includes(file.type)) {
        setImagenesPorLado((prev) => {
          const newImages = { ...prev };
          newImages[lado].push(file); // Se agrega la imagen al lado seleccionado
          return newImages;
        });
      } else {
        alert('Por favor, selecciona un archivo de imagen válido.');
      }
    });
  };

  // Maneja el cambio de lado seleccionado
  const handleLadoChange = (e) => {
    setLado(e.target.value);
  };

  // Elimina una imagen de un lado específico
  const handleRemoveImage = (lado, index) => {
    setImagenesPorLado((prev) => {
      const newImages = { ...prev };
      newImages[lado].splice(index, 1); // Eliminar la imagen en el índice seleccionado
      return newImages;
    });
  };

  // Maneja el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');  // Resetear el mensaje de error antes de hacer la solicitud

    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descripcion', descripcion);
    formData.append('trabajador', trabajador);
    formData.append('openings', openings);
    formData.append('fraccionamiento', fraccionamientoId);
    formData.append('loteId', loteId);

    // Añadir las imágenes al formData
    Object.keys(imagenesPorLado).forEach((lado) => {
      imagenesPorLado[lado].forEach((img) => {
        formData.append(lado, img); // Agregar las imágenes de cada lado
      });
    });

    try {
      const response = await axios.post(
        `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/${loteId}/incidencias`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Verificar que la respuesta sea exitosa
      if (response.status === 200 || response.status === 201) {
        onClose();  // Cierra el modal si la incidencia se envía exitosamente
      } else {
        // Si la respuesta no tiene un código exitoso, mostrar el error
        setErrorMessage('Hubo un problema al agregar la incidencia');
        onClose();
      }
    } catch (error) {
      // Si ocurre un error en la solicitud, mostrar un mensaje de error
      console.error('Error al agregar la incidencia:', error);
      setErrorMessage('Error al agregar la incidencia');
      onClose();

    } finally {
      setIsSubmitting(false);  // Termina el estado de "enviando"
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded-lg p-6 max-w-md mx-auto space-y-6"
    >
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        Agregar trabajo realizado por trabajador
      </h2>

      {/* Título */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Título:</label>
        <input
          type="text"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Ingresa el título"
        />
      </div>

      {/* Descripción */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Descripción:</label>
        <textarea
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Describe la incidencia"
          rows="4"
        />
      </div>

      {/* Trabajador */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Trabajador:</label>
        <select
          value={trabajador}
          onChange={(e) => setTrabajador(e.target.value)}
          required
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        >
          <option value="">Selecciona un trabajador</option>
          {['Ivan', 'Arturo', 'Ruben', 'Marcos', 'Yazmin', 'Ale', 'Valdo', 'Victor', 'Jancarlo', 'Luis', 'Angel', 'Cristian', 'Didier', 'Juan'].map((trabajador) => (
            <option key={trabajador} value={trabajador}>
              {trabajador}
            </option>
          ))}
        </select>
      </div>

      {/* Aperturas */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Aperturas:</label>
        <input
          type="text"
          value={openings}
          onChange={(e) => setOpenings(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          placeholder="Número de aperturas"
        />
      </div>

      {/* Selector de lado */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Seleccionar lado:</label>
        <select
          value={lado}
          onChange={handleLadoChange}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        >
          <option value="frente">Frente</option>
          <option value="izquierda">Izquierda</option>
          <option value="derecha">Derecha</option>
          <option value="atras">Atrás</option>
        </select>
      </div>

      {/* Campo de imagen */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 mb-1">Imagen:</label>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
        />
      </div>

      {/* Mostrar las imágenes cargadas para cada lado */}
      <div>
        {['frente', 'izquierda', 'derecha', 'atras'].map((side) => (
          <div key={side}>
            <h3>{side.charAt(0).toUpperCase() + side.slice(1)}</h3>
            {imagenesPorLado[side].map((img, index) => (
              <div key={index} className="relative mb-2">
                <img
                  src={URL.createObjectURL(img)}
                  alt={`${side} ${index}`}
                  className="w-20 h-20 object-cover"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(side, index)}
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full text-xs"
                >
                  X
                </button>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Mostrar mensaje de error si existe */}
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 rounded-md transition duration-200"
      >
        {isSubmitting ? 'Enviando...' : 'Agregar Incidencia'}
      </button>
    </form>
  );
};

export default AgregarIncidenciaForm;
