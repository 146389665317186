import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useRoute } from 'wouter';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
import '../styles/LoteDetails.css';
import FramerButton from './FramerButton';
import BackButton from './BackButton';
import AgregarIncidenciaForm from './AgregarIncidenciaForm';
import Modal from './Modal';
import ProtectedContent from './ProtectedContent';


alertify.set('notifier', 'position', 'top-right');

const LoteDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [lote, setLote] = useState(null);
  const [historialIncidencias, setHistorialIncidencias] = useState([]);
  const [, params] = useRoute("/:constructoraId/fraccionamientos/:fraccionamientoId/:loteId");
  const [selectedEstado, setSelectedEstado] = useState('');
  const [historialCargado, setHistorialCargado] = useState(false);
  const [showImagenModal, setShowImagenModal] = useState(false);
  const [imagenModalUrl, setImagenModalUrl] = useState('');
  const [isModdalOpen2, setIsModdalOpen2] = useState(false);
  const correctPassword = '1028807';

  const estadoColores = {
    Listo: 'green',
    proceso: 'blue',
    Incidencia: 'red',
  };

  const openModal2 = (image) => {
    setSelectedImage(image);
    setIsModdalOpen2(true);
  };

  const closeModal2 = () => {
    setSelectedImage('');
    setIsModdalOpen2(false);
  };

  useEffect(() => {
    if (!params.loteId) return;

    // Obtener datos del lote
    fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener el lote');
        }
        return response.json();
      })
      .then((data) => {
        setLote(data);
        setSelectedEstado(data.estado);
      })
      .catch((error) => {
        console.error(error);
        alertify.error('Error al obtener el lote');
      });

    // Solo cargar historial si no ha sido cargado
    if (!historialCargado) {
      fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}/incidencias`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error al obtener incidencias');
        }
        return response.json();
      })
      .then((data) => {
        // Asegurarse de que las incidencias corresponden al lote actual
        const incidenciasFiltradas = data.filter(incidencia => incidencia.lote === params.loteId);
        setHistorialIncidencias(incidenciasFiltradas);
        setHistorialCargado(true);
      })
      .catch((error) => {
        console.error(error);
        alertify.error('Error al obtener el historial de incidencias');
      });
    
    }
  }, [params, historialCargado]);

  const handleEditarLote = () => {
    const camposEditables = ['openings', 'vinil', 'basements', 'color'];
    editarCamposRecursivamente(camposEditables, 0);
  };

  const editarCamposRecursivamente = async (campos, index) => {
    if (index >= campos.length) {
      return;
    }

    const campo = campos[index];

    if (campo === 'openings') {
      const propiedadesOpenings = ['frente', 'derecha', 'izquierda', 'atras'];
      await editarPropiedadesOpenings(propiedadesOpenings, 0);
    } else {
      alertify.prompt(`Editar ${campo}:`, lote[campo] || '', async (e, nuevoValor) => {
        if (e) {
          const valorNumerico = parseFloat(nuevoValor);
          let valorFormateado;
          if (Number.isInteger(valorNumerico)) {
            valorFormateado = valorNumerico.toString(); // Solo el número entero
          } else {
            valorFormateado = valorNumerico.toFixed(1); // Un decimal
          }
          await mostrarModalEditarLote({ [campo]: valorFormateado });
        }
        editarCamposRecursivamente(campos, index + 1);
      });
    }
  };

  const editarPropiedadesOpenings = async (propiedades, index) => {
    if (index >= propiedades.length) {
      return;
    }

    const propiedad = propiedades[index];

    alertify.prompt(`Editar ${propiedad}:`, lote.openings[propiedad] || '', async (e, nuevoValor) => {
      if (e) {
        const valorNumerico = parseFloat(nuevoValor);
        let valorFormateado;
        if (Number.isInteger(valorNumerico)) {
          valorFormateado = valorNumerico.toString(); // Solo el número entero
        } else {
          valorFormateado = valorNumerico.toFixed(1); // Un decimal
        }
        const updatedOpenings = { ...lote.openings, [propiedad]: valorFormateado };
        await mostrarModalEditarLote({ openings: updatedOpenings });
      }
      editarPropiedadesOpenings(propiedades, index + 1);
    });
  };

  const mostrarModalEditarLote = async (camposEditados) => {
    try {
      const response = await fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(camposEditados),
      });

      if (response.ok) {
        setLote((prevLote) => ({ ...prevLote, ...camposEditados }));
        alertify.success('Lote editado exitosamente');
      } else {
        alertify.error('Error al editar el lote');
      }
    } catch (error) {
      alertify.error('Error al editar el lote');
    }
  };

  const formatFecha = (fechaString) => {
    const fecha = new Date(fechaString);
    return `${fecha.toLocaleDateString()} ${fecha.toLocaleTimeString()}`;
  };

  const iconColor = estadoColores[selectedEstado] || "yellow";

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleIncidenciaAgregada = () => {
    alertify.success('Incidencia agregada exitosamente');
    // Aquí puedes volver a cargar el historial de incidencias si es necesario
    fetch(`https://back.ivdian.ca/api/constructoras/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}/lotes/${params.loteId}/incidencias`)
      .then((response) => response.json())
      .then((data) => {
        setHistorialIncidencias(data);
      });
  };

  // Función para obtener las imágenes más recientes si el estado es "Listo"
  const obtenerImagenesOpenings = () => {
    if (selectedEstado === 'Listo') {
      const incidenciasListas = historialIncidencias.filter((incidencia) => incidencia.estado === 'Listo');
      if (incidenciasListas.length > 0) {
        const imagenesIncidencia = incidenciasListas[incidenciasListas.length - 1]; // Última incidencia con estado "Listo"
        return ['frente', 'derecha', 'izquierda', 'atras'].map((direction) => imagenesIncidencia.imagen);
      }
    }
    // Si no está "Listo", usamos las imágenes del lote
    return ['frente', 'derecha', 'izquierda', 'atras'].map((direction) => lote.imagenes[direction]);
  };

  return (
    <div className="container mt-6 px-4 sm:px-6 lg:px-8">
      {lote ? (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-gray-400 border-b border-gray-300 py-4 px-6 flex justify-between items-center">
            <BackButton destination={`/${params.constructoraId}/fraccionamientos/${params.fraccionamientoId}`} />
            <h2 className="text-2xl font-semibold text-white">
              Detalles del Lote <span className="text-3xl font-bold">{lote.numero_lote}</span>
            </h2>
            <div className="flex items-center space-x-4">
              <FramerButton
                className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-lg flex items-center shadow-md transition-transform"
                onClick={handleOpenModal}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add
              </FramerButton>
              <FontAwesomeIcon icon={faHome} size="lg" className={`text-${iconColor}-500`} />
              <ProtectedContent correctPassword={correctPassword}>
                <FramerButton
                  className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-lg flex items-center shadow-md transition-transform"
                  onClick={() => handleEditarLote(true)}
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Editar
                </FramerButton>
              </ProtectedContent>
            </div>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="mb-4">
                <p className="text-gray-800 font-semibold text-lg mb-2">Openings</p>
                {typeof lote.openings === 'object' ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    {['frente', 'derecha', 'izquierda', 'atras' , 'ruff'].map((direction, index) => (
                      <div key={direction} className="bg-white p-4 rounded shadow hover:scale-105 transition-transform">
                        <p className="font-bold text-gray-700">
                          {`${direction.charAt(0).toUpperCase() + direction.slice(1)}: ${lote.openings[direction] || '0.00'}`}
                        </p>

                        <img
                          src={`https://back.ivdian.ca/${obtenerImagenesOpenings()[index]}`}
                          alt={direction}
                          className="w-full h-auto rounded-lg shadow-md mb-2 cursor-pointer"
                          onClick={() => openModal2(`https://back.ivdian.ca/${obtenerImagenesOpenings()[index]}`)}
                        />
                      </div>
                    ))}
                    <div className="col-span-1 md:col-span-4">
                      <p className="font-semibold text-gray-800">
                        Total: {Object.values(lote.openings).reduce((sum, value) => sum + (parseFloat(value) || 0), 0)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="text-red-500">{lote.openings}</p>
                )}
                <Modal isOpen={isModdalOpen2} onClose={closeModal2}>
                  {selectedImage && <img src={selectedImage} alt="Zoomed" className="w-full h-auto max-h-screen" />}
                </Modal>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
              <div>
                <p className="text-gray-600 font-medium">Vinil</p>
                <p>{lote.vinil}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Basement</p>
                <p>{lote.basement}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Color</p>
                <p>{lote.color}</p>
              </div>
              <div>
                <p className="text-gray-600 font-medium">Estado</p>
                <p style={{ color: iconColor }}>{selectedEstado}</p>
              </div>
            </div>
            <div className="mt-6">
  <h3 className="text-lg font-semibold mb-4">Historial</h3>
  {historialIncidencias.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {historialIncidencias.map((incidencia) => (
        <div key={incidencia._id} className="bg-white rounded-lg shadow-md p-4 hover:scale-105 transition-transform">
          <h4 className="text-md font-semibold mb-2">Titulo: {incidencia.titulo}</h4>
          <p className="text-sm text-gray-600 mb-2">Descripcion: {incidencia.descripcion}</p>
          <span className="block text-sm text-gray-600 mb-2">Fecha: {formatFecha(incidencia.fecha)}</span>
          
          {/* Mostrar imágenes por lado con un diseño en grid */}
          {['frente', 'izquierda', 'derecha', 'atras'].map((lado) => (
            incidencia.imagenesPorLado[lado]?.length > 0 && (
              <div key={lado} className="mb-4">
                <p className="text-sm font-semibold text-gray-600">{lado.charAt(0).toUpperCase() + lado.slice(1)}</p>
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
                  {incidencia.imagenesPorLado[lado].map((imagen, index) => (
                    <img
                      key={index}
                      src={`https://back.ivdian.ca/${imagen}`}
                      alt={`${lado} ${index + 1}`}
                      className="w-full h-32 object-cover rounded-md cursor-pointer"
                      onClick={() => {
                        setImagenModalUrl(`https://back.ivdian.ca/${imagen}`);
                        setShowImagenModal(true);
                      }}
                    />
                  ))}
                </div>
              </div>
            )
          ))}
          
          <div className="mb-2">
            <span className="font-medium">Trabajador: </span>
            <span className="text-gray-700">{incidencia.trabajador}</span>
          </div>
          <div className="mb-2">
            <span className="font-medium">Openings: </span>
            <span className="text-gray-700">{incidencia.openings}</span>
          </div>
          <button
            onClick={() => {
              if (incidencia.imagenesPorLado.frente?.length > 0) {
                setImagenModalUrl(`https://back.ivdian.ca/${incidencia.imagenesPorLado.frente[0]}`);
              }
              setShowImagenModal(true);
            }}
            className="mt-2 bg-blue-500 text-white rounded-md py-1 px-3 hover:bg-blue-600 transition"
          >
            Ver imagen
          </button>
        </div>
      ))}
    </div>
  ) : (
    <p>No hay incidencias registradas.</p>
  )}
</div>
          </div>
          {/* Modal para agregar incidencia */}
<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
  <AgregarIncidenciaForm
    constructoraId={params.constructoraId}
    fraccionamientoId={params.fraccionamientoId}
    loteId={params.loteId}
    onClose={handleCloseModal}  // Aquí pasamos onClose
    onIncidenciaAgregada={handleIncidenciaAgregada}
  />
</Modal>


          {/* Modal para ver imagen */}
          {showImagenModal && (
            <Modal isOpen={showImagenModal} onClose={() => setShowImagenModal(false)}>
              <img src={imagenModalUrl} alt="Imagen de incidencia" className="w-full h-auto max-h-screen" />
            </Modal>
          )}
        </div>
      ) : (
        <p>Cargando lote...</p>
      )}
    </div>
  );
};

export default LoteDetails;