import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LoteList = ({ constructoraId, fraccionamientoId }) => {
  const [lotes, setLotes] = useState([]);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [totalOpenings, setTotalOpenings] = useState(0);

  useEffect(() => {
    // Fetch los lotes del fraccionamiento al cargar el componente
    const fetchLotes = async () => {
      try {
        const response = await axios.get(
          `https://back.ivdian.ca/api/constructoras/${constructoraId}/fraccionamientos/${fraccionamientoId}/lotes/fraccionamiento/${fraccionamientoId}`
        );
        console.log('Respuesta de la API:', response.data);

        // Ordenar los lotes de menor a mayor por número de lote
        const sortedLotes = response.data.sort((a, b) => a.numero_lote - b.numero_lote);

        setLotes(sortedLotes);
      } catch (error) {
        console.error('Error al obtener los lotes:', error);
      }
    };
  
    if (fraccionamientoId) {
      fetchLotes();
    }
  }, [constructoraId, fraccionamientoId]);
  
  const toggleLoteSelection = (numeroLote) => {
    setSelectedLotes((prevSelected) => {
      const updatedSelected = prevSelected.includes(numeroLote)
        ? prevSelected.filter((lote) => lote !== numeroLote)
        : [...prevSelected, numeroLote];

      calculateTotalOpenings(updatedSelected); // Llamar a la función para calcular el total
      return updatedSelected;
    });
  };

  const calculateTotalOpenings = (selected) => {
    let total = 0;
  
    selected.forEach((numeroLote) => {
      const lote = lotes.find((lote) => lote.numero_lote === numeroLote);
      console.log('Lote:', lote); // Para verificar el lote actual

      if (lote) {
        const openingsTotal =
          typeof lote.openings === 'object' && lote.openings !== null
            ? Object.values(lote.openings).reduce((sum, value) => {
                const numValue = parseFloat(value);
                return sum + (isNaN(numValue) ? 0 : numValue);
              }, 0)
            : 0;

        total += openingsTotal; // Sumar el total aquí
      }
    });
  
    setTotalOpenings(total); // Establecer el total
  };
  
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Lotes del Fraccionamiento</h2>
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200 text-gray-700">
            <th className="py-2 px-4 border-b border-gray-300 text-left">Lote</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">Total Openings</th>
            <th className="py-2 px-4 border-b border-gray-300 text-left">Seleccionar</th>
          </tr>
        </thead>
        <tbody>
          {lotes.map((lote) => {
            const openingsTotal =
              typeof lote.openings === 'object' && lote.openings !== null
                ? Object.values(lote.openings).reduce((sum, value) => {
                    const numValue = parseFloat(value);
                    return sum + (isNaN(numValue) ? 0 : numValue);
                  }, 0)
                : 0;

            return (
              <tr key={lote.numero_lote} className="hover:bg-gray-100 transition duration-200">
                <td className="py-2 px-4 border-b border-gray-300 font-bold">{lote.numero_lote}</td>
                <td className="py-2 px-4 border-b border-gray-300">{openingsTotal}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <input
                    type="checkbox"
                    checked={selectedLotes.includes(lote.numero_lote)}
                    onChange={() => toggleLoteSelection(lote.numero_lote)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3 className="text-lg font-semibold mt-4">Total Openings Seleccionados: <span className="text-blue-600">{totalOpenings}</span></h3>
    </div>
  );
};

export default LoteList;
